import React, { useCallback, useState } from 'react';
import { SubmitForm } from '@ui/SubmitForm';
import { FieldBuilder, formatPhoneNumber } from '@ui/FieldBuilder';
import StaticIcon from '@ui/StaticIcon';
import Typography from '@ui/Typography';
import Button from '@ui/Button';
import { useForm, useFormState, useWatch } from 'react-hook-form';
import { bem } from '@lib/bem';
import { t } from '@lib/i18n';
import clsx from 'clsx';
import { loginByCode } from '@api/handlers/auth';
import { webAppModel } from '@features/login-button';
import { getCurrentUser } from '@api/handlers/users';
import { registerCompany } from '@api/handlers/management-companies';
import Notice from '@lib/notifications';

import ReplaceMobileButton from './ReplaceMobileButton';

const formatDuration = (time) => {
  const minutes = Math.floor(time / 60);
  const seconds = time - minutes * 60;
  return `${minutes}:${seconds < 10 ? `0${seconds}` : seconds}`;
};

const {
  block,
  element,
} = bem('registration');

const SendCodeAgainButton = ({
  sendCode, mobile, canSendCode, children,
}) => {
  return (
    <Button
      invisible
      onClick={canSendCode ? () => sendCode({ mobile }) : null}
      className={clsx(
        'mx-auto',
        !canSendCode && 'cursor-default opacity-50',
      )}
    >
      <Typography
        variant="button2"
        weight="medium"
        color={canSendCode ? 'primary400' : 'secondary400'}
      >
        {children}
      </Typography>
    </Button>
  );
};

const ConfirmCode = ({ 
  mobile, onBack, leftTimeInSeconds, sendCode, canSendCode, data,
}) => {
  const confirmForm = useForm({ mode: 'onChange' });
  const {
    control,
    handleSubmit,
    setError,
  } = confirmForm;
  const { isValid } = useFormState({ control });

  const [longLoading, setLongLoading] = useState(false);

  const { login } = webAppModel({ inNewTab: false });

  const [alreadyRegistered, setAlreadyRegistered] = useState(false);

  const handleAlreadyRegisteredUser = useCallback(() => {
    setAlreadyRegistered(true);
  }, []);

  const handleCompanyRegister = useCallback(async (values) => {
    const {
      ok,
      responseBody,
      error,
    } = await registerCompany({
      data: values,
    });
    if (ok) {
      login();
      setLongLoading(false);
    } else if ((responseBody && responseBody.message) || error) {
      responseBody && responseBody.message
        ? Notice.error(responseBody.message, { className: 'registration-layout-toast' })
        : Notice.error(t('errors.server_wrong'), { className: 'registration-layout-toast' });
    }
  }, [login]);

  const onSubmitClick = useCallback(async (values) => {
    const response = await loginByCode({
      data: {
        mobile: mobile.number,
        code: values.verifyCode,
      },
    });

    setLongLoading(true);

    if (response.ok) {
      const { responseBody } = await getCurrentUser();
      if (responseBody && responseBody.management_company && responseBody.management_company.id) {
        handleAlreadyRegisteredUser();
      } else {
        handleCompanyRegister({ ...data, mobile: data.mobile.number, language: 'ru' });
      }
    } else {
      setLongLoading(false);
      confirmForm.reset();
      setError('verifyCode', { type: 'custom', message: t('registration.field_errors.wrong_code') });
    }
  }, [confirmForm, data, handleAlreadyRegisteredUser, handleCompanyRegister, mobile.number, setError]);

  const extraActionText = (() => {
    if (leftTimeInSeconds) {
      return t('registration.buttons.receive_code_again_through', { seconds: formatDuration(leftTimeInSeconds) });
    }
    if (leftTimeInSeconds === 0) {
      return t('registration.buttons.receive_code_again');
    }
  })();

  const verifyCodeLabel = (() => {
    return (
      <div {...element('mobile-label', {}, 'flex gap-0.5 items-center h-[18px] font-medium')}>
        <Typography variant="caption1">Для номера</Typography>
        <Typography variant="caption1" color="secondary300" {...element('mobile')}>
          {formatPhoneNumber(mobile)}
        </Typography>
        <StaticIcon name="edit" {...element('mobile-label-icon')} onClick={onBack} />
      </div>
    );
  })();

  let SubmitButtonProps = {
    onSubmit: onSubmitClick,
    submitText: t('registration.buttons.go_to_system'),
    canSubmit: isValid,
    longLoading,
  };

  if (alreadyRegistered) {
    SubmitButtonProps = {
      onSubmit: () => webAppModel({ inNewTab: false }).login(),
      submitText: t('login.title'),
    };
  }

  return (
    <div className="mt-5">
      <SubmitForm
        form={confirmForm}
        {...SubmitButtonProps}
      >
        <FieldBuilder.VerifyCode
          name="verifyCode"
          label={verifyCodeLabel}
          rules={{
            pattern: /\d{4}/,
            required: true,
          }}
          hint={alreadyRegistered && (
            <div className="flex items-start">
              <div>
                <StaticIcon name="success-check" className="h-[18px] w-[8px]" />
              </div>
              <p className="ml-[6px]">
                {t('registration.already_registered')}
              </p>
            </div>
          )}
          showError
          errorTypes={['custom']}
        />
      </SubmitForm>
      <div className="flex lg:mt-2 lg-down:mt-1">
        {
          alreadyRegistered ? (
            <ReplaceMobileButton onClick={onBack}>
              {t('registration.buttons.input_other_phone_number')}
            </ReplaceMobileButton>
          ) : (
            <SendCodeAgainButton
              sendCode={(values) => {
                confirmForm.reset();
                sendCode(values);
              }}
              canSendCode={canSendCode}
              mobile={mobile}
            >
              {extraActionText}
            </SendCodeAgainButton>
          )
        }
      </div>
    </div>
  );
};

export default ConfirmCode;
